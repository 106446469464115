// Using the scrollBehavior configuration does not take into account that the app is awaiting data before routing
// use mixin in the mounted lifecycle instead
export default {
    methods: {
        scrollToTop(smooth = false) {
            if (smooth) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                window.scrollTo({ top: 0 });
            }
        },
    },
};
