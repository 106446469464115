<template>
    <div class="page-heading__container">
        <h1
            v-if="$slots.title || title"
            class="page-heading__title"
        >
            <slot name="title" />
            {{ title }}
        </h1>
        <div
            v-if="$slots.actions"
            class="page-heading__actions"
        >
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    props: {
        title: {
            type: String,
        },
    },
};
</script>

<style lang='stylus'>
.page-heading {
    &__container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: $nebula-space-3x;
        justify-content: space-between;
        margin-bottom: $nebula-space-5x;
        margin-top: $nebula-space-3x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-wrap: nowrap;
        }
    }

    &__title {
        margin: 0;
    }

    &__actions, &__title {
        flex-basis: 100%;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-basis: auto;
        }
    }

    &__actions {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            gap: $nebula-space-2x;
        }
    }
}
</style>
