<template>
    <NebulaSwitch class="setting-row" :class="{'setting-row__right': right }" :labelText="data.display || 'Notification'" :isInverted="true">
        <template v-slot:checkbox>
            <input
                class="nebula-switch__input"
                id="example-1"
                @input="toggleSetting"
                name="basic-switch"
                type="checkbox"
                role="switch"
                :checked="data.setting"
            >
        </template>
    </NebulaSwitch>
</template>

<script>
import {
    NebulaSwitch,
} from '@discoveryedu/nebula-components';

export default {
    name: 'SettingRow',
    components: {
        NebulaSwitch,
    },
    props: {
        data: Object,
        right: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggleSetting() {
            this.$emit('setting-changed', this.data);
        },
    },
};
</script>

<style lang="stylus">
.setting-row {
    border-block-end: 1px solid $nebula-color-platform-neutral-300;
    justify-content: space-between;
    padding-block: $nebula-space-2x;
    width: 100%;

    &__right {
        justify-content: flex-start;
    }
}
</style>
