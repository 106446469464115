<template>
    <GrayBoxLoader class="notification-settings__loader" v-if="loading" />
    <div v-else class="notification-settings">
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="4">
                <NebulaTitleBlock
                    class="notification-settings__title-section"
                    title="Email Notifications"
                    type="section"
                    description="Select when you'll be notified when the following changes occur."
                />
            </NebulaColumn>
            <NebulaColumn :columns="8">
                <SettingRow
                    right
                    :data="{
                        setting: emailSettingAll,
                        destination: 'email',
                        type: ['all'],
                        display: 'Select All'
                    }"
                    @setting-changed="toggleSetting"
                />
                <div
                    v-for="(each, idx) in settings.email" :key="`setting-email-${idx}`"
                >
                    <SettingRow :data="{ ...each, destination: 'email' }" @setting-changed="toggleSetting" />
                </div>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="notification-settings__row">
            <NebulaColumn :columns="4">
                <NebulaTitleBlock
                    class="notification-settings__title-section"
                    title="App Notifications"
                    type="section"
                    description="Select when you'll be notified when the following changes occur."
                />
            </NebulaColumn>
            <NebulaColumn :columns="8">
                <SettingRow
                    right
                    :data="{
                        setting: appSettingAll,
                        destination: 'app',
                        type: ['all'],
                        display: 'Select All'
                    }"
                    @setting-changed="toggleSetting"
                />
                <div
                    v-for="(each, idx) in settings.app" :key="`setting-app-${idx}`"
                >
                    <SettingRow :data="{ ...each, destination: 'app' }" @setting-changed="toggleSetting" />
                </div>
            </NebulaColumn>
        </NebulaGridRow>
    </div>
</template>

<script>
import {
    NebulaGrid,
    NebulaTitleBlock,
    // NebulaSwitch,
} from '@discoveryedu/nebula-components';
import SettingRow from '@/components/settings/SettingRow.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'NotificationSettings',
    mixins: [createPayload],
    components: {
        GrayBoxLoader,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaTitleBlock,
        // NebulaSwitch,
        SettingRow,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        settings() {
            return this.$store.getters.settings;
        },
        emailSettingAll() {
            if (!this.settings) {
                return null;
            }
            const settings = this.settings.email && this.settings.email.map((each) => each.setting);
            return !settings.includes(false);
        },
        appSettingAll() {
            if (!this.settings) {
                return null;
            }
            const settings = this.settings.app && this.settings.app.map((each) => each.setting);
            return !settings.includes(false);
        },
    },
    methods: {
        async toggleSetting(data) {
            const togglePayload = await this.createPayload({
                ...data,
            });
            await this.$store.dispatch('toggleNotificationSetting', togglePayload);
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        await this.$store.dispatch('getNotificationSettings', payload);
        this.loading = false;
    },
};
</script>

<style lang="stylus">
.notification-settings {
    width: 100%;
    &__loader {
        min-height: auto;
    }

    &__row {
        padding-block-start: $nebula-space-5x;
        border-block-start: 1px solid $nebula-color-platform-neutral-300;
        margin-block-start: $nebula-space-5x;

        &:first-child {
            padding-block-start: 0;
        }

        &:last-child {
            margin-block-end: $nebula-space-10x;
        }
    }
    &__title-section {
        --nebula-font-size-header-level-5: $nebula-font-size-card-title;

        @media (min-width: $nebula-breakpoints-desktop) {
            padding-inline-end: $nebula-space-2x;
        }
        .nebula-title-block__description {
            margin-block-start: $nebula-space-half;
            font-size: $nebula-font-size-body-1;
        }
    }
}
</style>
