var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('GrayBoxLoader',{staticClass:"notification-settings__loader"}):_c('div',{staticClass:"notification-settings"},[_c('NebulaGridRow',{attrs:{"type":"flex"}},[_c('NebulaColumn',{attrs:{"columns":4}},[_c('NebulaTitleBlock',{staticClass:"notification-settings__title-section",attrs:{"title":"Email Notifications","type":"section","description":"Select when you'll be notified when the following changes occur."}})],1),_c('NebulaColumn',{attrs:{"columns":8}},[_c('SettingRow',{attrs:{"right":"","data":{
                    setting: _vm.emailSettingAll,
                    destination: 'email',
                    type: ['all'],
                    display: 'Select All'
                }},on:{"setting-changed":_vm.toggleSetting}}),_vm._l((_vm.settings.email),function(each,idx){return _c('div',{key:`setting-email-${idx}`},[_c('SettingRow',{attrs:{"data":{ ...each, destination: 'email' }},on:{"setting-changed":_vm.toggleSetting}})],1)})],2)],1),_c('NebulaGridRow',{staticClass:"notification-settings__row",attrs:{"type":"flex"}},[_c('NebulaColumn',{attrs:{"columns":4}},[_c('NebulaTitleBlock',{staticClass:"notification-settings__title-section",attrs:{"title":"App Notifications","type":"section","description":"Select when you'll be notified when the following changes occur."}})],1),_c('NebulaColumn',{attrs:{"columns":8}},[_c('SettingRow',{attrs:{"right":"","data":{
                    setting: _vm.appSettingAll,
                    destination: 'app',
                    type: ['all'],
                    display: 'Select All'
                }},on:{"setting-changed":_vm.toggleSetting}}),_vm._l((_vm.settings.app),function(each,idx){return _c('div',{key:`setting-app-${idx}`},[_c('SettingRow',{attrs:{"data":{ ...each, destination: 'app' }},on:{"setting-changed":_vm.toggleSetting}})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }